import React from 'react'

import Button from 'components/Button'
import { BannerContainer, Content, BannerText } from './Banner.styles'

interface Props {
  text: string
  image: string
  callToAction?: {
    text: string
    callback: () => void
    large?: boolean
  }
}

const Banner = ({ text, image, callToAction = false }: Props) => {
  return (
    <BannerContainer background={image}>
      <Content>
        <BannerText>{text}</BannerText>
        {callToAction && (
          <Button
            noShadow={true}
            onClick={callToAction.callback}
            large={callToAction.large}
          >
            {callToAction.text}
          </Button>
        )}
      </Content>
    </BannerContainer>
  )
}

export default Banner
