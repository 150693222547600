import styled from 'styled-components'

export const InputContainer = styled.div``

export const InputLabel = styled.div`
  font-family: 'Roboto', sans-serif;
  color: ${props => props.theme.colors.black};
  margin-bottom: 5px;
`

export const InputField = styled.input`
  border: 2px solid ${props => props.theme.colors.primary};
  border-radius: 8px;
  width: 100%;
  height: 40px;
  color: ${props => props.theme.colors.primary};
  padding: 10px;
  text-transform: uppercase;
`
