import styled from 'styled-components'
import { ButtonBack, ButtonNext } from 'pure-react-carousel'

export const IndexContainer = styled.div`
  /* background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%2300d8b1' fill-opacity='0.27'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */
`

export const WelcomeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  span {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 800;
  }

  @media (min-width: 756px) {
    padding: 50px 0;
    span {
      font-size: 28px;
    }
  }
`

export const WelcomeSubText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  width: 60%;
  margin-top: 5px;
  @media (min-width: 756px) {
    font-size: 20px;
  }
`

export const SectionContainer = styled.div`
  margin: 20px 0;
  padding: 0 20px;
  @media (min-width: 756px) {
    padding: 0 10%;
    margin: 40px 0;
  }
`

export const FluidSectionContainer = styled(SectionContainer)`
  padding: 0;
  @media (min-width: 756px) {
    padding: 0 10%;
  }
`

export const CarouselContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;

  .horizontalSlider___281Ls {
    height: 350px;
  }

  @media (min-width: 756px) {
    .horizontalSlider___281Ls {
      height: 450px;
    }
  }
`

export const CarouselIcons = styled.div`
  color: ${props => props.theme.colors.primary};
  width: 11px;
  height: 20px;
`

export const CarouselBack = styled(ButtonBack)`
  color: ${props => props.theme.colors.primary};
  font-size: 40px;
  border: none;
  background: none;
  position: absolute;
  left: 0;
  top: 20%;
`

export const CarouselNext = styled(ButtonNext)`
  color: ${props => props.theme.colors.primary};
  font-size: 40px;
  border: none;
  background: none;
  right: 0;
  top: 20%;
  position: absolute;
`

export const FormContainer = styled.div`
  padding: 20px;
  background-color: #e2edf3;
  margin-top: 15px;
  font-family: 'Robot', sans-serif;

  section:first-of-type {
    margin-bottom: 25px;
  }
  h3 {
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 15px;
  }

  div {
    margin-bottom: 15px;
    font-size: 14px;
    width: 60%;
  }

  @media (min-width: 756px) {
    display: flex;
    padding: 30px;
    border-radius: 8px;
    section:first-of-type {
      margin-bottom: 0;
    }
    section {
      padding: 0 5%;
    }
    h3 {
      font-size: 20px;
    }
    div {
      font-size: 16px;
    }
  }
`

export const InputContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  align-items: end;
  @media (min-width: 756px) {
    width: 60%;
  }
`

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  div:first-of-type {
    font-weight: 500;
  }
  section {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 300;
  }

  img {
    width: 80%;
    align-self: center;
    margin-top: 40px;
  }

  @media (min-width: 756px) {
    font-size: 24px;
    div:first-of-type {
      width: 50%;
      font-size: 28px;
      align-self: center;
    }
    section {
      font-size: 16px;
    }
    img {
      width: 40%;
    }
  }
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
`
