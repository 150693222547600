import React from 'react'

import Button from 'components/Button'
import {
  CardContainer,
  CardWrapper,
  CardTitle,
  CardImage,
  CardText,
} from './ServiceCard.styles'

interface Props {
  title: string
  image: string
  text: string
  onMoreInfo: () => void
}

const ServiceCard = ({ title, image, text, onMoreInfo }: Props) => {
  return (
    <CardContainer>
      <CardWrapper>
        <CardTitle>{title}</CardTitle>
        <CardImage image={image} />
        <CardText>{text}</CardText>
        <Button onClick={onMoreInfo}>Mais informações</Button>
      </CardWrapper>
    </CardContainer>
  )
}

export default ServiceCard
