import styled from 'styled-components'

export const SectionTitleContainer = styled.div`
  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
  display: flex;

  @media (min-width: 756px) {
    h2 {
      font-size: 28px;
    }
  }
`

export const Bar = styled.div`
  background: ${props => props.theme.colors.gradient.bottomRight};
  width: 4px;
  margin-right: 5px;
`
