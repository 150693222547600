import styled from 'styled-components'

interface BannerContainerProps {
  background: string
}

export const BannerContainer = styled.div<BannerContainerProps>`
  width: 100%;
  height: 250px;
  background: url(${props => props.background}) center center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0 20px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${props => props.theme.colors.gradient.bottomRight};
    opacity: 0.3;
  }

  @media (min-width: 756px) {
    height: 650px;
    padding: 0 10%;
  }
`

export const Content = styled.div`
  z-index: 2;

  button {
    margin-top: 10px;
  }
`

export const BannerText = styled.div`
  color: white;
  font-family: 'Raleway', sans-serif;
  font-size: 22px;
  font-weight: 800;
  width: 60%;
  @media (min-width: 756px) {
    width: 50%;
    font-size: 60px;
  }
`
