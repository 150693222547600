import React from 'react'
import { InputContainer, InputLabel, InputField } from './Input.styles'

interface Props {
  label: string
  onChange: (value: string) => void
  value: string
}

const Input = ({ label, onChange, value }: Props) => {
  return (
    <InputContainer>
      <InputLabel>{label}</InputLabel>
      <InputField
        value={value}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          onChange(e.currentTarget.value)
        }
      />
    </InputContainer>
  )
}

export default Input
