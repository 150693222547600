import React, { ReactChild } from 'react'

import { TextContainer, Underline } from './UnderlinedText.styles'

interface Props {
  children: string
}

const UnderlinedText = ({ children }: Props) => {
  return (
    <TextContainer>
      <span>{children}</span>
      <Underline />
    </TextContainer>
  )
}

export default UnderlinedText
