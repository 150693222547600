import styled from 'styled-components'

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const CardWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
  }

  @media (min-width: 736px) {
    button {
      align-self: flex-start;
    }
  }
`

export const CardTitle = styled.h3`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin-bottom: 15px;

  @media (min-width: 736px) {
    font-size: 20px;
    margin: 20px 0;
  }
`

interface CardImageProps {
  image: string
}

export const CardImage = styled.div<CardImageProps>`
  border-radius: 8px;
  background: url(${props => props.image}) center center no-repeat;
  width: 100%;
  height: 130px;
  background-size: cover;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${props => props.theme.colors.gradient.bottomRight};
    opacity: 0.4;
    border-radius: 8px;
  }
`

export const CardText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin: 20px 0;

  @media (min-width: 736px) {
    font-size: 16px;
    margin: 20px 0;
  }
`
