import React, { useState } from 'react'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import { isMobile } from 'react-device-detect'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import Fade from 'react-reveal/Fade'
import { navigate } from 'gatsby'

import 'pure-react-carousel/dist/react-carousel.es.css'

import Layout from 'layouts/index'
import Banner from 'containers/Banner'

import UnderlinedText from 'components/UnderlinedText'
import Button from 'components/Button'
import Input from 'components/Input'
import SectionTitle from 'components/SectionTitle'
import ServiceCard from 'components/ServiceCard'

import bannerDog from 'images/banner-dog.jpg'
import veterinarian from 'images/veterinarian.jpg'
import lab from 'images/laboratorio_1.jpg'
import ultrassom from 'images/ultrassom.jpg'
import cirurgia from 'images/cirurgia_2.jpg'
import internament from 'images/internamento_1.jpg'

import {
  IndexContainer,
  WelcomeContainer,
  WelcomeSubText,
  SectionContainer,
  FluidSectionContainer,
  CarouselContainer,
  CarouselBack,
  CarouselNext,
  FormContainer,
  InputContainer,
} from '../pageStyles/index.styles'

const cardsInfo = [
  {
    title: 'Laboratório',
    image: lab,
    text:
      'A Clínica Veterinária Derosso possui parceria com o Laboratório Veterinário Próvita, especializado em patologia clínica e tem como responsável técnica a médica veterinária Drª Ana Laura D’Amico Fam.',
    onMoreInfo: () => navigate('/servicos/laboratorio'),
  },
  {
    title: 'Internamento',
    image: internament,
    text:
      'Uma doença em nossos pets é sempre um momento delicado.Isso pode se agravar, pois é inevitável a permanecia deles em hospitalização.',
    onMoreInfo: () => navigate('/servicos/internamento'),
  },
  {
    title: 'Diagnóstico por Imagem',
    image: ultrassom,
    text:
      'O Setor de Diagnóstico por Imagem da Clínica Veterinária Derosso conta com equipamentos modernos que produzem imagens de alta qualidade e conta profissionais especializados.',
    onMoreInfo: () => navigate('/servicos/diagnostico'),
  },
  {
    title: 'Cirurgia',
    image: cirurgia,
    text:
      'O Setor de Diagnóstico por Imagem da Clínica Veterinária Derosso conta com equipamentos modernos que produzem imagens de alta qualidade e conta profissionais especializados.',
    onMoreInfo: () => navigate('/servicos/cirurgia'),
  },
  {
    title: 'Especialidades',
    image: veterinarian,
    text:
      'Um centro cirúrgico deve sempre estar bem equipado para todo e qualquer imprevisto que possa ocorrer durante um procedimento. Monitor multiparamétrico, desfibrilador, ventilador mecânico, fornecimento constante de oxigênio são ferramentas essenciais em qualquer centro cirúrgico.',
    onMoreInfo: () => navigate('/servicos/especialidades'),
  },
]

const Homepage = () => {
  const [resultValue, setResultValue] = useState('')
  return (
    <Layout>
      <IndexContainer>
        <Banner
          image={bannerDog}
          text='A SAÚDE DO SEU MELHOR AMIGO EM PRIMEIRO LUGAR!'
          callToAction={{
            text: 'AGENDE UMA CONSULTA',
            callback: () => navigate('https://wa.me/554132758180'),
            large: true,
          }}
        />
        <Fade>
          <WelcomeContainer>
            <UnderlinedText>Bem Vindo</UnderlinedText>
            <span>a Clínica Veterinária Derosso!</span>
            <WelcomeSubText>
              Oferecemos uma ampla gama de serviços de saúde, bem-estar e
              veterinária.
            </WelcomeSubText>
          </WelcomeContainer>
        </Fade>

        <SectionContainer>
          <SectionTitle>Serviços Veterinários</SectionTitle>
          <Fade>
            {isMobile ? (
              <CarouselContainer>
                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={125}
                  totalSlides={cardsInfo.length}
                >
                  <Slider>
                    {cardsInfo.map((card, i) => (
                      <Slide index={i} key={i}>
                        <ServiceCard
                          title={card.title}
                          image={card.image}
                          text={card.text}
                          onMoreInfo={card.onMoreInfo}
                        />
                      </Slide>
                    ))}
                  </Slider>
                  <CarouselBack>
                    <MdChevronLeft />
                  </CarouselBack>
                  <CarouselNext>
                    <MdChevronRight />
                  </CarouselNext>
                </CarouselProvider>
              </CarouselContainer>
            ) : (
              <CarouselContainer>
                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={125}
                  totalSlides={cardsInfo.length}
                  visibleSlides={3}
                  step={3}
                >
                  <Slider>
                    {cardsInfo.map((card, i) => (
                      <Slide index={i} key={i}>
                        <ServiceCard
                          title={card.title}
                          image={card.image}
                          text={card.text}
                          onMoreInfo={card.onMoreInfo}
                        />
                      </Slide>
                    ))}
                  </Slider>
                  <CarouselBack>
                    <MdChevronLeft />
                  </CarouselBack>
                  <CarouselNext>
                    <MdChevronRight />
                  </CarouselNext>
                </CarouselProvider>
              </CarouselContainer>
            )}
          </Fade>
        </SectionContainer>

        <SectionContainer id='resultado-de-exame'>
          <SectionTitle>Resultado de Ultrassom ou Raio X</SectionTitle>
          <Fade>
            <InputContainer>
              <Input
                label={'ID de Acesso'}
                value={resultValue}
                onChange={setResultValue}
              />
              <Button
                onClick={() =>
                  window.open(
                    `https://resultados.drnuvem.com.br/${resultValue}`
                  )
                }
              >
                Confirmar
              </Button>
            </InputContainer>
          </Fade>
        </SectionContainer>
        <SectionContainer>
          <SectionTitle>Formulários</SectionTitle>
        </SectionContainer>
        <Fade>
          <FluidSectionContainer>
            <FormContainer>
              <section>
                <h3>CARTA DE SOLICITAÇÃO EXAME DE IMAGEM</h3>
                <div>
                  Clique em "Imprimir formulário" abaixo para preencher o
                  formuário e fazer a impressão da carta de solicitação exame de
                  imagem.
                </div>
                <a href='/carta-solicitacao'>
                  <Button>Imprimir formulário</Button>
                </a>
              </section>
            </FormContainer>
          </FluidSectionContainer>
        </Fade>

        {/* <SectionContainer>
          <AboutContainer>
            <UnderlinedText>Clínica Veterinária Derosso</UnderlinedText>
            <section>
              A Clínica Veterinária Derosso já perdeu a conta dos animais que
              cuidou na região de Curitiba e São José dos Pinhais, Desde sua
              inauguração, em 1992, o bem estar dos bichos e a alegria de seus
              donos vendo-os saudáveis, é a nossa grande recompensa, Uma ampla
              estrutura além de alta capacitação técnica, encontra-se a
              disposição 24 horas por dia, atendendo finais de semana e feriado
              qualquer emergência.
            </section>
            <img src={front} alt='Frente da loja' />
          </AboutContainer>
        </SectionContainer> */}
      </IndexContainer>
    </Layout>
  )
}

export default Homepage
