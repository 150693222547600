import styled from 'styled-components'

export const TextContainer = styled.div`
  font-family: 'Raleway', sans-serif;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;

  span {
    z-index: 1;
    position: relative;
  }
`

export const Underline = styled.div`
  height: 4px;
  background: ${props => props.theme.colors.gradient.bottomRight};
  position: absolute;
  bottom: 5%;
  width: calc(50px + 80%);
  z-index: 0;
`
