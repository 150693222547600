import styled from 'styled-components'

interface Props {
  noShadow?: boolean
  large?: boolean
}

export default styled.button<Props>`
  background: ${props => props.theme.colors.gradient.bottomRight};
  width: ${props => (props.large ? '300px' : '134px')};
  height: 34px;
  border-radius: 8px;
  color: ${props => props.theme.colors.white};
  border: none;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  box-shadow: ${props => (props.noShadow ? 'none' : '3px 4px 10px #7ddccb')};
  cursor: pointer;

  &:active {
    box-shadow: none;
  }

  @media (min-width: 756px) {
    width: ${props => (props.large ? '220px' : '160px')};
    height: 40px;
    font-size: 16px;
  }
`
