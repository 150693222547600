import React from 'react'
import { SectionTitleContainer, Bar } from './SectionTitle.styles'
interface Props {
  children: string
}

const SectionTitle = ({ children }: Props) => {
  return (
    <SectionTitleContainer>
      <Bar />
      <h2>{children}</h2>
    </SectionTitleContainer>
  )
}

export default SectionTitle
